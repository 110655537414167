import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>End User Side Features</button>
                                <button onClick={() => scrollTo("instructor")}>Instructor Side Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Side Features for Learning Management System (LMS)</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User-Friendly Dashboard</h3>
                                    <ul>
                                        <li><span>Intuitive and visually appealing dashboard for easy navigation.</span></li>
                                        <li><span>Quick access to courses, progress, and announcements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Course Catalog</h3>
                                    <ul>
                                        <li><span>Browse and search functionalities for discovering available courses.</span></li>
                                        <li><span>Detailed course descriptions, objectives, and prerequisites.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Enrollment & Registration</h3>
                                    <ul>
                                        <li><span>Seamless enrollment process for selected courses.</span></li>
                                        <li><span>Registration history and confirmation notifications.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Content Access & Delivery</h3>
                                    <ul>
                                        <li><span>Centralized access to course materials, including lectures, readings, and multimedia content.</span></li>
                                        <li><span>Various content delivery formats, such as videos, documents, and interactive presentations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Interactive Assessments</h3>
                                    <ul>
                                        <li><span>Online quizzes, assignments, and assessments with real-time feedback.</span></li>
                                        <li><span>Progress tracking and performance analytics.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Discussion Forums</h3>
                                    <ul>
                                        <li><span>Collaborative discussion forums for interaction with instructors and peers.</span></li>
                                        <li><span>Threaded discussions for organized and engaging conversations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Virtual Classrooms</h3>
                                    <ul>
                                        <li><span>Live or recorded virtual sessions for lectures, Q&A sessions, and discussions.</span></li>
                                        <li><span>Integration with video conferencing tools for seamless communication.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Progress Tracking</h3>
                                    <ul>
                                        <li><span>Personalized progress dashboards displaying completed and pending tasks.</span></li>
                                        <li><span>Visual representations of course progress and achievements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Grading & Feedback</h3>
                                    <ul>
                                        <li><span>Instant access to grades and feedback on assessments.</span></li>
                                        <li><span>Notifications for new grades and comments from instructors.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span>Responsive design for access on various devices, including smartphones and tablets.</span></li>
                                        <li><span>Mobile applications for on-the-go learning.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Collaborative Tools</h3>
                                    <ul>
                                        <li><span>Group projects and collaboration tools for teamwork.</span></li>
                                        <li><span>Shared documents and collaborative editing features.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Calendar Integration</h3>
                                    <ul>
                                        <li><span>Synchronization with personal calendars for assignment deadlines and course events.</span></li>
                                        <li><span>Reminders and notifications for upcoming activities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Resource Libraries</h3>
                                    <ul>
                                        <li><span>Access to supplementary learning resources and materials.</span></li>
                                        <li><span>Personalized libraries for saving and organizing resources.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Accessibility Features</h3>
                                    <ul>
                                        <li><span>Accessibility options for differently-abled users, ensuring inclusivity.</span></li>
                                        <li><span>Compatibility with screen readers and alternative navigation methods.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Certificates & Achievements</h3>
                                    <ul>
                                        <li><span>Automated generation of certificates upon course completion.</span></li>
                                        <li><span>Display of badges and achievements for milestones reached.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Social Learning Features</h3>
                                    <ul>
                                        <li><span>Integration with social media for sharing achievements and progress.</span></li>
                                        <li><span>Social learning platforms for community engagement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customization Options</h3>
                                    <ul>
                                        <li><span>Personalization features for adjusting preferences and settings.</span></li>
                                        <li><span>Theme and layout customization for a personalized learning experience.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Offline Learning Resources</h3>
                                    <ul>
                                        <li><span>Downloadable resources for offline learning.</span></li>
                                        <li><span>Access to course materials without requiring a continuous internet connection.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Support & Help Center</h3>
                                    <ul>
                                        <li><span>Access to user support, FAQs, and help documentation.</span></li>
                                        <li><span>Help desk or chat support for immediate assistance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Other Tools</h3>
                                    <ul>
                                        <li><span>Integration with productivity tools, note-taking apps, and cloud storage services.</span></li>
                                        <li><span>Compatibility with third-party applications for enhanced functionality.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="instructor">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Instructor Side Features for Learning Management System (LMS)</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Course Creation & Management</h3>
                                    <ul>
                                        <li><span>Intuitive tools for creating, organizing, and managing course content.</span></li>
                                        <li><span>Flexibility to structure courses with modules, lessons, and assessments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Assignment Creation & Grading</h3>
                                    <ul>
                                        <li><span>Easy-to-use interface for creating various types of assignments.</span></li>
                                        <li><span>Efficient grading tools, including rubrics and comment features.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Content Delivery</h3>
                                    <ul>
                                        <li><span>Upload and organize multimedia content, lectures, and readings.</span></li>
                                        <li><span>Compatibility with various file formats and media types.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Discussion Forum Moderation</h3>
                                    <ul>
                                        <li><span>Moderation tools for overseeing and participating in discussion forums.</span></li>
                                        <li><span>Encouragement of constructive and collaborative discussions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Virtual Classroom Hosting</h3>
                                    <ul>
                                        <li><span>Tools for hosting live or recorded virtual classrooms.</span></li>
                                        <li><span>Integration with video conferencing tools for seamless delivery.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Assessment Creation & Analytics</h3>
                                    <ul>
                                        <li><span>Creation of quizzes, exams, and assessments with diverse question types.</span></li>
                                        <li><span>Analytics dashboard for tracking student performance and engagement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Gradebook Management</h3>
                                    <ul>
                                        <li><span>Centralized gradebook for efficient grading and performance tracking.</span></li>
                                        <li><span>Options for weighted grades and custom grading scales.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Channels</h3>
                                    <ul>
                                        <li><span>Announcements and messaging tools for effective communication.</span></li>
                                        <li><span>Email integration for seamless communication with students.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Progress Tracking Tools</h3>
                                    <ul>
                                        <li><span>Monitoring tools for tracking student progress and participation.</span></li>
                                        <li><span>Notifications for students falling behind or excelling.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Attendance Tracking</h3>
                                    <ul>
                                        <li><span>Digital tools for tracking and managing attendance.</span></li>
                                        <li><span>Integration with virtual attendance tracking features.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Collaborative Tools for Group Work</h3>
                                    <ul>
                                        <li><span>Features supporting collaborative group projects.</span></li>
                                        <li><span>Shared spaces for group discussions and document collaboration.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Resource Libraries Management</h3>
                                    <ul>
                                        <li><span>Tools for managing and updating resource libraries.</span></li>
                                        <li><span>Integration with external educational resources.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Assessment Review</h3>
                                    <ul>
                                        <li><span>Efficient tools for providing feedback on assignments and assessments.</span></li>
                                        <li><span>Options for reviewing assessments with students.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Course Analytics</h3>
                                    <ul>
                                        <li><span>Comprehensive analytics on course engagement, completion rates, and user interactions.</span></li>
                                        <li><span>Data-driven insights for course improvement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customization & Branding</h3>
                                    <ul>
                                        <li><span>Options for customizing the appearance and layout of the course.</span></li>
                                        <li><span>Branding features for a cohesive learning experience.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Role-Based Permissions</h3>
                                    <ul>
                                        <li><span>Role-based access control to manage teaching assistants and course collaborators.</span></li>
                                        <li><span>Ensuring secure and controlled access to course materials.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Learning Tools</h3>
                                    <ul>
                                        <li><span>Compatibility with external learning tools and applications.</span></li>
                                        <li><span>Seamless integration with third-party educational technologies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Real-time Collaboration Features</h3>
                                    <ul>
                                        <li><span>Real-time collaboration tools for synchronous interactions.</span></li>
                                        <li><span>Integration with collaborative editing and communication tools.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility for Instructors</h3>
                                    <ul>
                                        <li><span>Access to LMS features on mobile devices for flexibility.</span></li>
                                        <li><span>Mobile applications for instructor management on the go.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training & Support Resources</h3>
                                    <ul>
                                        <li><span>Resources for instructor training on LMS functionalities.</span></li>
                                        <li><span>Ongoing support and assistance for technical or instructional issues.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features for Learning Management System (LMS)</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Centralized Dashboard</h3>
                                    <ul>
                                        <li><span>Comprehensive dashboard for overseeing multiple courses and user activities.</span></li>
                                        <li><span>Quick access to key metrics and administrative tools.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Management System</h3>
                                    <ul>
                                        <li><span>Tools for managing user accounts, including students, instructors, and administrators.</span></li>
                                        <li><span>Role-based access control for ensuring data security.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Course Management & Organization</h3>
                                    <ul>
                                        <li><span>Centralized tools for creating, updating, and organizing courses.</span></li>
                                        <li><span>Streamlined course enrollment and removal processes.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Data Analytics & Reporting</h3>
                                    <ul>
                                        <li><span>Analytics dashboard for monitoring overall LMS performance.</span></li>
                                        <li><span>Customizable reports on user engagement, course completion, and assessment results.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with External Systems</h3>
                                    <ul>
                                        <li><span>Compatibility with external systems, including authentication and other educational technologies.</span></li>
                                        <li><span>Seamless integration with institutional databases.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security Measures</h3>
                                    <ul>
                                        <li><span>Implementation of robust security measures to protect sensitive educational data.</span></li>
                                        <li><span>Compliance with data protection regulations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customization & Branding</h3>
                                    <ul>
                                        <li><span>Customization options for the appearance and branding of the LMS.</span></li>
                                        <li><span>Consistent branding across courses and modules.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Automated Notifications</h3>
                                    <ul>
                                        <li><span>Automated notifications for important events, such as course updates or system maintenance.</span></li>
                                        <li><span>Email and in-app notifications for users and administrators.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Course Enrollment Oversight</h3>
                                    <ul>
                                        <li><span>Tools for overseeing course enrollment, withdrawals, and student progression.</span></li>
                                        <li><span>Support for resolving enrollment-related issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Content Management System (CMS) Integration</h3>
                                    <ul>
                                        <li><span>Integration with a CMS for managing and updating course content.</span></li>
                                        <li><span>Version control and rollback features for content changes.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Support & Help Desk</h3>
                                    <ul>
                                        <li><span>Centralized help desk for addressing user issues and inquiries.</span></li>
                                        <li><span>Resources and FAQs for self-help and guidance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>LMS Updates & Maintenance</h3>
                                    <ul>
                                        <li><span>Tools for managing updates and maintenance of the LMS.</span></li>
                                        <li><span>Ensuring system functionality, security, and compatibility.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Backup & Recovery System</h3>
                                    <ul>
                                        <li><span>Regular automated backups of LMS data.</span></li>
                                        <li><span>Recovery tools for restoring data in case of system issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training & Onboarding Resources</h3>
                                    <ul>
                                        <li><span>Resources for training and onboarding new administrators.</span></li>
                                        <li><span>Continuous education on LMS features and updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Authentication & Authorization</h3>
                                    <ul>
                                        <li><span>Secure authentication methods for administrators.</span></li>
                                        <li><span>Role-based authorization to control access to administrative functionalities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Financial Management Integration</h3>
                                    <ul>
                                        <li><span>Integration with financial systems for course fee tracking.</span></li>
                                        <li><span>Tools for financial reporting and reconciliation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Institutional Policies</h3>
                                    <ul>
                                        <li><span>Alignment of the LMS with institutional policies and guidelines.</span></li>
                                        <li><span>Tools for implementing and enforcing policy compliance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Cross-Platform Compatibility</h3>
                                    <ul>
                                        <li><span>Compatibility with various devices and operating systems.</span></li>
                                        <li><span>Responsive design for access on desktops, tablets, and smartphones.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Accessibility Features</h3>
                                    <ul>
                                        <li><span>Ensuring accessibility options for differently-abled administrators.</span></li>
                                        <li><span>Compliance with accessibility standards.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Based Panel for Students/Parents</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for<br />Students</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon3.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Based Panel for Teacher/Instructor</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Based Panel<br />for Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;