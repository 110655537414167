import React from 'react';

const ContentSection = () => {
    return (
        <>
            <section className="capabilities__area p-relative grey-bg-12 pt-100 pb-100 fix">
                <div
                    className="capabilities__thumb p-absolute"
                    style={{ background: `url(assets/img/about-bg1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="capabilities__content">
                                <div className="section__title section__title-3 mb-25">
                                    <h2>We Offers!</h2>
                                </div>
                                <div className="capabilities__list">
                                    <p>Sjain Ventures is venturing into the future of education with its all-encompassing E-Learning Platform, seamlessly merging innovation and education. At the core of this venture is a potent combination of a Learning Management System (LMS) and a School Management System, forming a unified and comprehensive educational ecosystem.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="blog__details-wrapper">
                                <div className="blog__text">
                                    <p>The <span>Learning Management System</span> stands as the foundation of this platform, aiming to enhance the learning journey for students, teachers, and administrators. It serves as a centralized hub for overseeing course content, assignments, assessments, and collaborative tools. Students are granted access to a user-friendly interface, fostering interactive and engaging learning experiences that support both individual and collaborative knowledge acquisition. For teachers, advanced features facilitate content delivery, real-time assessments, and progress tracking.</p>
                                    <p className="mb-0">In tandem with the LMS, the <span>School Management System</span> contributes efficiency to the administrative facets of education. It optimizes school operations by incorporating modules for admissions, attendance tracking, grading, and communication. This integrated approach ensures a smooth flow of information among educators, students, and parents, cultivating a transparent and communicative educational environment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );
};

export default ContentSection;