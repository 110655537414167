import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/Helper/ScrollTop";
import BackToTop from "./components/Helper/BackToTop";
import Home from './components/Home/index';
import About from './components/About/index';
import Blog from './components/Blog/index';
import Contact from './components/Contact/index';
import LMS from './components/LMS/index';
import SMS from './components/SMS/index';

function App() {
    return (
        <>
            {/* <Router basename="/name"> */}
            <Router>
                <ScrollTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/features/learning-management-system" element={<LMS />} />
                    <Route path="/features/school-management-system" element={<SMS />} />
                </Routes>
                <BackToTop />
            </Router>
        </>
    );
}

export default App;
