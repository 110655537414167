import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("student")}>Student Side Features</button>
                                <button onClick={() => scrollTo("teacher")}>Teacher Side Features</button>
                                <button onClick={() => scrollTo("admin")}>School/Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="student">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Student Side Features for School Management System (SMS)</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Personal Dashboard</h3>
                                    <ul>
                                        <li><span>Individualized dashboard for students displaying their academic information.</span></li>
                                        <li><span>Quick access to announcements, assignments, and upcoming events.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Class Schedule & Timetable</h3>
                                    <ul>
                                        <li><span>Display of personalized class schedules and timetables.</span></li>
                                        <li><span>Notification alerts for class changes or cancellations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Attendance Tracking</h3>
                                    <ul>
                                        <li><span>Access to attendance records for each class.</span></li>
                                        <li><span>Notifications for low attendance or important attendance-related updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Grades & Report Cards</h3>
                                    <ul>
                                        <li><span>Secure access to grades and academic performance.</span></li>
                                        <li><span>Detailed report cards with subject-wise grades and teacher comments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Assignment & Homework Submission</h3>
                                    <ul>
                                        <li><span>Online platform for submitting assignments and homework.</span></li>
                                        <li><span>Notification reminders for assignment deadlines.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Exam Schedules & Results</h3>
                                    <ul>
                                        <li><span>Display of exam schedules and access to result announcements.</span></li>
                                        <li><span>Instant notification of exam results with detailed breakdowns.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Channels</h3>
                                    <ul>
                                        <li><span>Messaging system for communication with teachers and school staff.</span></li>
                                        <li><span>Announcement board for important school-wide updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Library Resources & Book Tracking</h3>
                                    <ul>
                                        <li><span>Access to the school library catalog and digital resources.</span></li>
                                        <li><span>Tools for tracking borrowed and returned books.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Fee Payment & Financial Information</h3>
                                    <ul>
                                        <li><span>Online fee payment options with detailed financial statements.</span></li>
                                        <li><span>Alerts for pending fee payments and payment receipts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Extracurricular Activity Tracking</h3>
                                    <ul>
                                        <li><span>Overview of participation in extracurricular activities.</span></li>
                                        <li><span>Notifications for upcoming events and activities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Transportation Information</h3>
                                    <ul>
                                        <li><span>Details on school transportation routes and schedules.</span></li>
                                        <li><span>Notifications for any changes or delays in transportation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Health Records Access</h3>
                                    <ul>
                                        <li><span>Secure access to personal health records and medical history.</span></li>
                                        <li><span>Alerts for upcoming health check-ups or vaccinations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>School Calendar & Events</h3>
                                    <ul>
                                        <li><span>Display of the school calendar with upcoming events.</span></li>
                                        <li><span>Integration with personal calendars for event reminders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Online Learning Resources</h3>
                                    <ul>
                                        <li><span>Access to digital learning resources, study materials, and e-books.</span></li>
                                        <li><span>Integration with online learning platforms.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Parent-Teacher Meeting Scheduling</h3>
                                    <ul>
                                        <li><span>Tools for scheduling parent-teacher meetings.</span></li>
                                        <li><span>Reminders and notifications for scheduled meetings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Complaints Submission</h3>
                                    <ul>
                                        <li><span>Platform for submitting feedback or raising concerns.</span></li>
                                        <li><span>System for tracking the status of submitted feedback.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Career Counseling & Guidance</h3>
                                    <ul>
                                        <li><span>Access to career counseling resources and guidance.</span></li>
                                        <li><span>Information on courses, colleges, and career paths.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Social & Collaboration Features</h3>
                                    <ul>
                                        <li><span>Social features for collaboration with peers on academic projects.</span></li>
                                        <li><span>Integration with study groups and collaborative platforms.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Personal Development Tracking</h3>
                                    <ul>
                                        <li><span>Tools for setting personal academic and development goals.</span></li>
                                        <li><span>Progress tracking and achievement celebration.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Privacy Measures</h3>
                                    <ul>
                                        <li><span>Robust security measures to protect student data.</span></li>
                                        <li><span>Compliance with data protection regulations for privacy.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="teacher">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Teacher Side Features for School Management System (SMS)</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Class Roster & Student Profiles</h3>
                                    <ul>
                                        <li><span>Access to a comprehensive class roster with detailed student profiles.</span></li>
                                        <li><span>Quick overview of student information, including contact details and academic records.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Attendance Management</h3>
                                    <ul>
                                        <li><span>Tools for taking and managing daily attendance for each class.</span></li>
                                        <li><span>Real-time visibility into attendance records and trends.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Gradebook & Assessment Tools</h3>
                                    <ul>
                                        <li><span>Digital gradebook for efficient recording and tracking of student grades.</span></li>
                                        <li><span>Tools for creating and managing assessments, quizzes, and exams.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Assignment & Homework Management</h3>
                                    <ul>
                                        <li><span>Online platform for assigning and collecting homework and assignments.</span></li>
                                        <li><span>Notification alerts for overdue assignments and submission reminders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication with Students & Parents</h3>
                                    <ul>
                                        <li><span>Integrated messaging system for communicating with students and parents.</span></li>
                                        <li><span>Announcement features for sharing important updates and information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Lesson Planning & Resource Management</h3>
                                    <ul>
                                        <li><span>Digital tools for planning and organizing lesson content.</span></li>
                                        <li><span>Access to a repository of teaching resources, including documents, presentations, and multimedia.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Timetable & Schedule Access</h3>
                                    <ul>
                                        <li><span>Display of personal teaching schedule and class timetables.</span></li>
                                        <li><span>Real-time updates on any changes to the schedule.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Professional Development Tracking</h3>
                                    <ul>
                                        <li><span>System for tracking and managing professional development activities.</span></li>
                                        <li><span>Access to training materials and resources.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Student Progress Tracking</h3>
                                    <ul>
                                        <li><span>Progress tracking tools for monitoring individual student performance.</span></li>
                                        <li><span>Analytics and reports on student achievements and areas for improvement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Parent-Teacher Meeting Management</h3>
                                    <ul>
                                        <li><span>Tools for scheduling and managing parent-teacher meetings.</span></li>
                                        <li><span>Access to past meeting records and notes.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Access to Student Health Information</h3>
                                    <ul>
                                        <li><span>Secure access to student health records and medical information.</span></li>
                                        <li><span>Awareness of any health-related considerations for students.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Library Resources Access</h3>
                                    <ul>
                                        <li><span>Access to the school library catalog and digital resources.</span></li>
                                        <li><span>Tools for recommending resources to students.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Extracurricular Activity Participation Tracking</h3>
                                    <ul>
                                        <li><span>Overview of students' participation in extracurricular activities.</span></li>
                                        <li><span>Tools for managing and organizing extracurricular events.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Collaboration with Other Teachers</h3>
                                    <ul>
                                        <li><span>Collaborative tools for planning and coordinating with other teachers.</span></li>
                                        <li><span>Shared spaces for collaborative lesson planning and resource sharing.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Assessment Review</h3>
                                    <ul>
                                        <li><span>Efficient tools for providing feedback on assignments and assessments.</span></li>
                                        <li><span>Options for reviewing assessments with students.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Access to School Calendar & Events</h3>
                                    <ul>
                                        <li><span>Display of the school calendar with upcoming events and activities.</span></li>
                                        <li><span>Integration with personal calendars for event reminders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Professional Communication Tools</h3>
                                    <ul>
                                        <li><span>Email integration and other communication tools for professional correspondence.</span></li>
                                        <li><span>Access to important school-wide announcements and updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Privacy Measures</h3>
                                    <ul>
                                        <li><span>Adherence to security measures to protect student data.</span></li>
                                        <li><span>Compliance with data protection regulations for privacy.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Classroom Resource Management</h3>
                                    <ul>
                                        <li><span>Tools for managing classroom resources, including textbooks and teaching aids.</span></li>
                                        <li><span>Inventory tracking and requisition tools.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility for Teachers</h3>
                                    <ul>
                                        <li><span>Access to SMS features on mobile devices for flexibility.</span></li>
                                        <li><span>Mobile applications for teachers to stay connected on the go.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>School/Admin Side Features for School Management System (SMS)</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Centralized Administrative Dashboard</h3>
                                    <ul>
                                        <li><span>Comprehensive dashboard for school administrators to oversee various functionalities.</span></li>
                                        <li><span>Quick access to key metrics, reports, and administrative tools.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Student Enrollment & Profile Management</h3>
                                    <ul>
                                        <li><span>Tools for managing student admissions, profiles, and enrollment records.</span></li>
                                        <li><span>Secure storage and retrieval of student information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Teacher & Staff Management</h3>
                                    <ul>
                                        <li><span>Personnel management system for teachers and staff.</span></li>
                                        <li><span>Access to staff profiles, schedules, and performance tracking.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Class & Section Management</h3>
                                    <ul>
                                        <li><span>Efficient tools for creating and managing classes and sections.</span></li>
                                        <li><span>Class assignment and roster management features.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Timetable & Academic Scheduling</h3>
                                    <ul>
                                        <li><span>Creation and management of class schedules and academic timetables.</span></li>
                                        <li><span>Tools for adjusting schedules and managing resource allocation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Attendance Management System</h3>
                                    <ul>
                                        <li><span>Automated attendance tracking system for all students and staff.</span></li>
                                        <li><span>Real-time visibility into attendance records.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Grading & Exam Management</h3>
                                    <ul>
                                        <li><span>Gradebook management for tracking and recording student grades.</span></li>
                                        <li><span>Tools for exam scheduling, result processing, and report generation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication & Announcement System</h3>
                                    <ul>
                                        <li><span>Integrated communication channels for reaching out to students, teachers, and parents.</span></li>
                                        <li><span>Announcement features for disseminating important information.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Fee Management & Financial Tracking</h3>
                                    <ul>
                                        <li><span>Online fee payment system with financial reporting.</span></li>
                                        <li><span>Tools for tracking fee collections, pending payments, and generating financial statements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Library & Resource Management</h3>
                                    <ul>
                                        <li><span>Library catalog management with digital resource tracking.</span></li>
                                        <li><span>Tools for managing borrowed and returned books.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Transportation Management</h3>
                                    <ul>
                                        <li><span>Management system for school transportation routes, schedules, and vehicles.</span></li>
                                        <li><span>Real-time tracking of school buses and transportation-related updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Health & Medical Records System</h3>
                                    <ul>
                                        <li><span>Secure storage and management of student health records.</span></li>
                                        <li><span>Tools for tracking medical check-ups, vaccinations, and health-related incidents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Extracurricular Activity Tracking</h3>
                                    <ul>
                                        <li><span>Overview of student participation in extracurricular activities.</span></li>
                                        <li><span>Tools for organizing and tracking events and competitions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Staff Professional Development</h3>
                                    <ul>
                                        <li><span>System for tracking and managing professional development activities for staff.</span></li>
                                        <li><span>Tools for setting goals and tracking progress.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Facility & Resource Booking</h3>
                                    <ul>
                                        <li><span>System for booking and managing school facilities and resources.</span></li>
                                        <li><span>Tools for scheduling events, meetings, and activities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Access Control</h3>
                                    <ul>
                                        <li><span>Security measures to control access to sensitive data.</span></li>
                                        <li><span>Role-based access control to limit access to specific functionalities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Parent-Teacher Meeting Management</h3>
                                    <ul>
                                        <li><span>Tools for scheduling and managing parent-teacher meetings.</span></li>
                                        <li><span>Notifications and reminders for scheduled meetings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Admissions & Enrollment System</h3>
                                    <ul>
                                        <li><span>Automated admissions process and enrollment management.</span></li>
                                        <li><span>Application tracking, interview scheduling, and admission status updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Complaints Handling</h3>
                                    <ul>
                                        <li><span>System for collecting and addressing feedback and complaints.</span></li>
                                        <li><span>Tools for tracking the status and resolution of submitted feedback.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customizable Settings & Configuration</h3>
                                    <ul>
                                        <li><span>Customization options for adapting the SMS to the school's specific needs.</span></li>
                                        <li><span>Configuration tools for adjusting settings and parameters.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100 grey-bg-12">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-2 offset-lg-1">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Based Panel for Students/Parents</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for<br />Students</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon3.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Based Panel for Teacher</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for<br />Teacher</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Based Panel for Admin/School</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;