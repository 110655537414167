import React from 'react';

const FaqSection = () => {
    return (
        <>
            <section className="faq__area pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="accordionExample">
                        <div className="col-xl-6">
                            <div className="faq__accordion">
                                <div className="accordion">
                                    <div className="card">
                                        <div className="card-header accordion-header" id="acc_1" >
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                                    What is an LMS system, and how does it benefit organizations?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_1" className="collapse" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>An LMS, or Learning Management System, is a software application that facilitates the creation, management, and delivery of educational content. It benefits organizations by providing a centralized platform for training, improving efficiency, and tracking employee development.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_2">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                                    How can an LMS system enhance employee training and development?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>LMS systems enhance employee training by offering a scalable and customizable platform for creating, delivering, and tracking training programs. This ensures consistent learning experiences and allows for personalized development paths.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_3">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                                    What types of content can be managed within an LMS system?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>LMS systems can manage a variety of content types, including documents, videos, presentations, quizzes, and interactive simulations. This versatility accommodates diverse learning styles and preferences.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_4">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                                    Is it possible to integrate an LMS system with existing company software and tools?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, our LMS systems offer integration capabilities, allowing seamless connections with HR software, CRM systems, and other tools. This integration streamlines processes and ensures a more cohesive learning environment.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-4 mb-lg-0">
                                        <div className="card-header" id="acc_5">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_5" aria-expanded="true" aria-controls="collapse_5">
                                                    How does an LMS system ensure data security and privacy?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_5" className="collapse" aria-labelledby="acc_5" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>LMS systems implement robust security measures such as data encryption, access controls, and regular audits to safeguard sensitive learner information. Compliance with data protection regulations is a priority for our LMS.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="faq__accordion">
                                <div className="accordion">
                                    <div className="card">
                                        <div className="card-header" id="acc_6">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_6" aria-expanded="true" aria-controls="collapse_6">
                                                    Can an LMS system be accessed on mobile devices?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_6" className="collapse" aria-labelledby="acc_6" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, Our LMS systems are designed with mobile compatibility in mind. Users can access courses, resources, and assessments on smartphones and tablets, providing flexibility for learning on the go.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_7">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_7" aria-expanded="true" aria-controls="collapse_7">
                                                    What reporting and analytics features are typically available in an LMS system?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_7" className="collapse" aria-labelledby="acc_7" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>LMS systems offer reporting tools that track user progress, completion rates, and assessment scores. Analytics provide valuable insights for administrators, helping them make informed decisions about training effectiveness and user engagement.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_8">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_8" aria-expanded="true" aria-controls="collapse_8">
                                                    How does gamification work in an LMS system, and what benefits does it offer?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_8" className="collapse" aria-labelledby="acc_8" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Gamification in an LMS involves incorporating game-like elements such as badges, leaderboards, and rewards to enhance user motivation and engagement. This approach can boost participation, improve learning retention, and make the training experience more enjoyable.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_9">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_9" aria-expanded="true" aria-controls="collapse_9">
                                                    Can an LMS system support compliance training and certifications?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_9" className="collapse" aria-labelledby="acc_9" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, Our LMS systems have features specifically designed for compliance training. They can track and manage mandatory training requirements, issue certifications upon completion, and provide documentation for auditing purposes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_10">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_10" aria-expanded="true" aria-controls="collapse_10">
                                                    What kind of technical support is available for users of an LMS system?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_10" className="collapse" aria-labelledby="acc_10" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Our LMS offer various levels of technical support, including online documentation, email support, and often live chat or phone support. Training resources and user forums are also commonly provided to assist with any issues or questions that may arise.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqSection;