import React from 'react';
import { LuMonitorSpeaker, LuFileSpreadsheet, LuPencilRuler, LuFileStack, LuFileBarChart2, LuShieldCheck, LuCalendarCheck2 } from "react-icons/lu";
import { GoGear, GoLocation, GoChecklist } from "react-icons/go";
import { RiCameraLensFill } from "react-icons/ri";
import { MdOutlineWebStories } from "react-icons/md";
import { PiUserCirclePlusBold, PiGraduationCap, PiCertificate } from "react-icons/pi";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2"
import { IoGameControllerOutline } from "react-icons/io5";
import { VscFeedback } from "react-icons/vsc";
import { GrIntegration, GrLineChart } from "react-icons/gr";
import { BsDatabaseLock } from "react-icons/bs";
import { TbLockAccess } from "react-icons/tb";
import { LiaHandHoldingUsdSolid } from "react-icons/lia";

const FeaturesSection = () => {
    return (
        <>
            <section className="features__area pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center mb-55">
                                <h2>Key Features</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="features__wrapper">
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuMonitorSpeaker /></i>
                                        </div>
                                        <div>
                                            <h3>Responsive Design</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuFileSpreadsheet /></i>
                                        </div>
                                        <div>
                                            <h3>Course & Catalogue Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><GoGear /></i>
                                        </div>
                                        <div>
                                            <h3>Automation</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><GoLocation /></i>
                                        </div>
                                        <div>
                                            <h3>Localization</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><RiCameraLensFill /></i>
                                        </div>
                                        <div>
                                            <h3>AI-based Proctoring</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><MdOutlineWebStories /></i>
                                        </div>
                                        <div>
                                            <h3>In App & In Web Experience</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><PiUserCirclePlusBold /></i>
                                        </div>
                                        <div>
                                            <h3>User-Friendly Interface</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuPencilRuler /></i>
                                        </div>
                                        <div>
                                            <h3>Course Creation Tools</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><HiOutlineDevicePhoneMobile /></i>
                                        </div>
                                        <div>
                                            <h3>Mobile Accessibility</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuFileStack /></i>
                                        </div>
                                        <div>
                                            <h3>Multi-format Content</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><IoGameControllerOutline /></i>
                                        </div>
                                        <div>
                                            <h3>Gamification</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><VscFeedback /></i>
                                        </div>
                                        <div>
                                            <h3>Assessment & Feedback</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><PiGraduationCap /></i>
                                        </div>
                                        <div>
                                            <h3>Social Learning</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><PiCertificate /></i>
                                        </div>
                                        <div>
                                            <h3>Certification</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuFileBarChart2 /></i>
                                        </div>
                                        <div>
                                            <h3>Analytics & Reporting</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><GrIntegration /></i>
                                        </div>
                                        <div>
                                            <h3>Integration Friendly</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><GrLineChart /></i>
                                        </div>
                                        <div>
                                            <h3>Progress Tracking</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><BsDatabaseLock /></i>
                                        </div>
                                        <div>
                                            <h3>Data Encryption</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbLockAccess /></i>
                                        </div>
                                        <div>
                                            <h3>Role-Based Access Control</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuShieldCheck /></i>
                                        </div>
                                        <div>
                                            <h3>Regular Security Audits</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuCalendarCheck2 /></i>
                                        </div>
                                        <div>
                                            <h3>Dairy & Attendance</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LiaHandHoldingUsdSolid /></i>
                                        </div>
                                        <div>
                                            <h3>Fee Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><GoChecklist /></i>
                                        </div>
                                        <div>
                                            <h3>Compliance with GDPR & HIPAA</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeaturesSection;